.custom-calendar .react-datepicker__header.react-datepicker__header--custom select,
.custom-calendar .date-input,
.custom-calendar .dateInput {
  background-color: #e8f3ff;
  border-radius: 3px;
  padding: 4px 33px 4px 14px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130.1%;
  border: none;
  color: #0055ff;
}

.calendar-align-left {
  left: -55px;
}

.custom-calendar .react-datepicker__header.react-datepicker__header--custom {
  background: white;
  border-bottom: 0;
}

.custom-calendar .react-datepicker__week,
.custom-calendar .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}

.custom-calendar {
  padding: 15px;
  font-family: "Manrope";
}

.custom-calendar .react-datepicker__month {
  margin: 0;
}

.custom-calendar .custom-date {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 9px;
  text-align: center;
  width: 24px;
  height: 24px;
  color: #373c3c;

  &.react-datepicker__day--disabled {
    color: #87878a8a;
    background: #f4f4f4;
    border-radius: 50%;
  }
}

.custom-calendar
  .react-datepicker__day.custom-date.react-datepicker__day--today.react-datepicker__day--weekend {
  border: 1px solid #ff8438;
  border-radius: 50%;
}

.react-datepicker__day.custom-date.react-datepicker__day--selected.react-datepicker__day--today.react-datepicker__day--weekend,
.custom-calendar .selected-date,
.custom-calendar .react-datepicker__day--selected.custom-date,
.custom-calendar .react-datepicker__day--keyboard-selected,
.custom-calendar .react-datepicker__month-text--keyboard-selected,
.custom-calendar .react-datepicker__quarter-text--keyboard-selected,
.custom-calendar .react-datepicker__year-text--keyboard-selected {
  background: #0055ff;
  border-radius: 50%;
  font-family: "Mukta", "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #0055ff;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.custom-calendar .custom-date:hover {
  border-radius: 50%;
}

.custom-calendar .react-datepicker__day-name:first-letter {
  font-size: 12px;
}

.custom-calendar .react-datepicker__day-name {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #afb1b1;
  margin: 22px 0 15px;
  font-size: 0;
  height: 16px;
  display: block;
}

.custom-calendar.react-datepicker {
  padding: 15px 18px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
}

.custom-calendar .react-datepicker__triangle::before,
.custom-calendar .react-datepicker__triangle::after {
  border: none !important;
}

.react-datepicker__day--in-range {
  border-radius: 1rem !important;
  background-color: #e4effa !important;

  &.react-datepicker__day--range-start {
    background-color: #0055ff !important;
    color: #fff !important;
  }

  &.react-datepicker__day--range-end {
    background-color: #0055ff !important;
    color: #fff !important;
  }
}

.custom-icon-pos-right .react-datepicker__view-calendar-icon input {
  padding-left: 0.75rem;
}
.custom-icon-pos-right .react-datepicker__view-calendar-icon .react-datepicker__close-icon {
  right: 24px;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: calc(50% - 16px);
}
