.mode-of-transport-dd {
  .select__menu {
    width: auto;
  }
  .select__option {
    background-color: white !important;
    color: #0055ff;
    cursor: pointer;
    display: flex;
    padding: 14px;
    width: 54px;
    user-select: none;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border: 1px solid #d7d8d8;
    margin: 14px;
    border-radius: 4px;
    height: 54px;
    &.select__option--is-selected,
    &:hover {
      border-color: #0055ff;
    }
  }
  .select__indicator-separator {
    display: none;
  }
  .select__menu-list {
    display: flex;
  }
  .select__value-container {
    border-radius: 8px;
  }
  .select__single-value {
    color: #0055ff;
    margin: 0 2px;
  }
  .select__control {
    border-color: #d7d8d8;
  }
  .select__value-container input {
    position: absolute;
  }
}
.multi-select {
  .select__control {
    border-color: #d7d8d8;
  }
  .select__value-container input {
    position: absolute;
  }
}
.blue.multi-select {
  .select__control {
    border-color: #0055ff;
    background-color: #0055ff;
    border-radius: 16px;
    color: white;
    height: 26px;
    min-height: 26px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .select__menu {
    margin-top: 5px;
  }
  .select__placeholder {
    color: white;
  }
}
