@import "react-phone-number-input/style.css";

.PhoneInput {
  .PhoneInputInput {
    width: 100%;
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border: 1px solid #d7d8d8;
    border-radius: 0.375rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
  }
}
